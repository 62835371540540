<template>
  <Transition name="fade" mode="out-in">
    <span :key="text" class="animated-text">
      <span
        v-for="(character, index) in currentText"
        :key="`${text}-${index}`"
        :style="{ '--index': index }"
        class="character"
        >{{ character }}</span
      >
    </span>
  </Transition>
</template>

<script lang="ts" setup>
const props = defineProps<{
  text: string
}>()

const currentText = computed(() => props.text.match(/.\s*/g) || [])

const textLength = computed(() => currentText.value.length)
</script>

<style lang="scss" scoped>
$duration: 0.2s;
$fraction: calc($duration / v-bind(textLength));

.character {
  display: inline-flex;
  white-space: pre;
  animation-name: appear;
  animation-duration: calc($fraction + 0.2s);
  animation-timing-function: ease;
  animation-delay: calc(var(--index) * $fraction);
  animation-fill-mode: both;
}

.previous-text {
  position: absolute;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
