import { useIntervalFn } from '@vueuse/core'

export default function useIntervalArray<T>(array: T[], interval = 1500) {
  const currentIndex = ref(0)

  const next = () => {
    currentIndex.value = (currentIndex.value + 1) % array.length
  }

  useIntervalFn(next, interval)

  return computed(() => array[currentIndex.value])
}
